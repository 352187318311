import { css } from '@linaria/core';
import React, { useCallback, useEffect } from 'react';
import { ContentCard } from '../../components/styles';
import { IconButton } from '../../composing/CSButton';
import { FlexRow } from '../../composing/Flex';
import { SpacerH, Spacing } from '../../composing/Spacing';
import { PieChart } from '../../theme/NewIcons';
import { useAuth, useUser } from '../auth/AuthContext';
import { useEphemeralModal } from '../modal/Modal';
import { ProfileAvatar } from '../../components/Avatar';
import PseudoInputButton from 'src/components/PseudoInputButton';
import { ZIndexLayers } from 'src/theme/constants';
import EditMemo from '../memo/EditMemo';
import { PortfolioShareModal } from '../share/ShareModal';
import UploadFileButton from 'src/components/UploadFileButton';
import { FeedItem, TradeFeedItem } from '@commonstock/common/src/api/feed';
import { LinkTradeButton, LinkTradeButtonVariant } from '../link-content/LinkPreviousTrade';
import { useRouter } from 'next/router';
import { TEMP_MEMO } from '../memo/constans';
import Tooltip from 'src/components/Tooltip';
import { useGetLinkStatus } from '@commonstock/common/src/api/user';
type Props = {
  prependItem?: (feedItem: FeedItem) => void;
}; // eslint-disable-next-line @typescript-eslint/no-unused-vars

export const FeedCreatePostContent = ({
  prependItem
}: Props) => {
  const {
    userUuid
  } = useAuth();
  const [profile] = useUser();
  const modal = useEphemeralModal();
  const prompt = `What's on your mind?`;
  const openPortfolioModal = useCallback(() => {
    const onImageSelect = (image: string) => {
      modal(null);
      modal(<EditMemo isModal files={[image]} isPortfolio prependItem={prependItem} />);
    };

    profile && modal(<PortfolioShareModal onImageSelect={onImageSelect} uuid={profile.user_uuid} user={{ ...profile,
      uuid: profile.user_uuid
    }} />);
  }, [modal, prependItem, profile]);
  const onInputClick = useCallback(() => {
    modal(<EditMemo isModal prependItem={prependItem} />);
  }, [modal, prependItem]);
  const router = useRouter();
  useEffect(() => {
    const {
      toggleMemo
    } = router.query;
    const tempMemo = JSON.parse(sessionStorage.getItem(TEMP_MEMO) || '0');
    if (tempMemo && toggleMemo) modal(<EditMemo isModal />); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFileUpload = useCallback((file: File) => {
    modal(<EditMemo isModal files={[file]} prependItem={prependItem} />);
  }, [modal, prependItem]);
  const onLinkTrades = useCallback((trades: TradeFeedItem[]) => {
    modal(<EditMemo isModal tradeFeedItems={trades} prependItem={prependItem} />);
  }, [modal, prependItem]);
  const [linkStatus] = useGetLinkStatus({
    meta: {
      user_uuid: userUuid
    }
  });
  const hasLinkedBroker = Boolean(linkStatus?.has_visible_synced_broker);
  if (!profile) return null;
  return <Spacing padding={[0, 0, 1, 0]}>
      <FlexRow alignCenter>
        <ProfileAvatar username={profile.username} name={profile.name} avatar={profile.picture_raw} />
        <SpacerH rem={0.75} />
        <PseudoInputButton onClick={onInputClick} prompt={prompt} />
        <SpacerH rem={0.75} />
        <Tooltip tip={hasLinkedBroker ? `Share your portfolio` : 'You are not sharing your portfolio'}>
          <IconButton onClick={hasLinkedBroker ? openPortfolioModal : undefined} aria-label={'create a post with portfolio'} className={buttonClass}>
            <PieChart size={1.25} />
          </IconButton>
        </Tooltip>
        <SpacerH rem={0.75} />
        <Tooltip tip={`Share an image`}>
          <UploadFileButton onFileUpload={onFileUpload} />
        </Tooltip>
        <SpacerH rem={0.75} />
        <Tooltip tip={`Share trades`}>
          <LinkTradeButton buttonVariant={LinkTradeButtonVariant.ICON} onClick={onLinkTrades} trades={[]} />
        </Tooltip>
      </FlexRow>
    </Spacing>;
};

function FeedCreatePost(props: Props) {
  return <Spacing padding={[1, 1, 0.75, 1]} margin={[0, 0, 1]}>
      <ContentCard className={slightlyUpZindex}>
        <FeedCreatePostContent {...props} />
      </ContentCard>
    </Spacing>;
}

export default FeedCreatePost;
const slightlyUpZindex = "s1v6qcot";
const buttonClass = "b5i4tcn";

require("../../../.linaria-cache/packages/oxcart/src/scopes/feed/FeedCreatePost.linaria.module.css");